.navbar {
  background-color: lightgray;
  padding: 10px 20px !important;
  font-size: 1.2rem;
}

.user-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  padding-right: 20px;
  margin-bottom: 10px; 
}

/* Style for the user name */
.user-name-display {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 8px; 
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand-text {
  font-size: 2.5rem; 
  font-weight: bold;
  margin-left: 10px; 
}

/* This will highlight the active nav */
.active {
  background-color: gray;
  color: white !important;
  border-radius: 5px;
  padding: 5px;
  border: 2px solid transparent; /* Reserve space for border */
}

button[disabled] {
  background-color: gray !important;
  border: none !important;
  cursor: not-allowed;
}

/* Change background when hover */
.nav-link {
  border: 2px solid transparent; /* Reserve space for border */
  padding: 5px; /* padding is consistent */
}

.nav-link:hover {
  background-color: #525355;
  color: white !important;
  border-radius: 5px;
  border: 2px solid #525355; /* Matching hover border to avoid shift */
}

.left-align {
  text-align: left;
}