body {
    background-image: url('./assets/images/MITcampus.jpg'); /* Adjust the path as needed */
    background-size: cover; /* This ensures the image covers the entire page */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-attachment: fixed; /* Keeps the image fixed during scrolling */
    background-position: center; /* Centers the image */
    margin: 0; /* Removes default margin */
    padding: 0; /* Removes default padding */
    font-family: Arial, sans-serif; /* Apply consistent font across pages */
  }

  .card {
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    border: none; /* Remove default border */
    background-color: #f8f9fa; /* Light background color */
  }
  
  .card-header {
    background-color: maroon; /* Add a background color to the header */
    color: white; /* White text for contrast */
    font-weight: bold; /* Bold text */
    border-radius: 10px 10px 0 0; /* Rounded top corners */
    text-align: center; /* Centered text */
    padding: 10px; /* Add some padding */
  }
  
  .card-body {
    padding: 20px; /* Add padding inside the card */
    background-color: #ffffff; /* White background for the body */
    border-radius: 0 0 10px 10px; /* Rounded bottom corners */
    color: #333; /* Darker text for better readability */
  }
  
  .card-title {
    font-size: 1.5rem; /* Increase the font size of the title */
    color: maroon; /* Blue color for the title */
    margin-bottom: 15px; /* Add some space below the title */
  }
  
  .card-text {
    font-size: 1rem; /* Normal text size */
    line-height: 1.5; /* Improve readability with line spacing */
    color: #666; /* Grey color for text */
  }
  
  .card-footer {
    background-color: maroon; /* Footer background color */
    color: white; /* White text for contrast */
    text-align: center; /* Center text */
    padding: 10px; /* Padding inside the footer */
    border-radius: 0 0 10px 10px; /* Rounded bottom corners */
  }

  .custom-card {
    margin-top: 20px;
    margin-right: 20px;
  }
  
  .custom-header {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 20px;
  }
  
  .custom-bold-row {
    font-weight: bold;
    border-bottom: 1px solid black;
    margin-top: 20px;
  }
  
  .custom-padding-top {
    padding-top: 20px;
  }
  
  .custom-text-left {
    text-align: left;
    font-size: 14px;
  }
  
  .custom-margin-bottom {
    margin-bottom: 50px;
  }
  
  .custom-margin-bottom-small {
    margin-bottom: 10px;
  }